import { FaChevronDown } from '@react-icons/all-files/fa/FaChevronDown';
import { HiOutlinePlus } from '@react-icons/all-files/hi/HiOutlinePlus';
import { MdRadioButtonChecked } from '@react-icons/all-files/md/MdRadioButtonChecked';
import { MdRadioButtonUnchecked } from '@react-icons/all-files/md/MdRadioButtonUnchecked';
import cls from 'clsx';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { AButton } from '@/components/atoms/A/A';
import {
  RadioGroup,
  RadioInput,
  RadioInputLabelProps,
} from '@/components/atoms/RadioSwitch';
import {
  H3,
  Label,
  TypographyProps,
} from '@/components/atoms/Typography/Typography';
import { AccordionSingle } from '@/components/molecules/Accordion/Accordion';
import { AccordionItem } from '@/components/molecules/AccordionItem/AccordionItem';
import { countriesData } from '@/data/countries';
import { languagesData } from '@/data/languages';
import { getCountryConfigFromLocale } from '@/lib/i18n/getCountryConfigFromLocale';
import { LocaleSelectBackButton } from '../LocaleSelectBackButton/LocaleSelectBackButton';
import styles from './LocaleSelectContent.module.scss';

export interface LocaleSelectContentProps {
  isBackButtonVisible?: boolean;
  isCountriesScreenOpen?: boolean;
  selectedLocale?: string;
  typographyVersion?: TypographyProps['version'];
  onClick?: () => void;
  onLocaleChange: (locale: string) => void;
}

export const LocaleSelectContent = ({
  isBackButtonVisible = true,
  isCountriesScreenOpen = false,
  selectedLocale,
  onClick,
  onLocaleChange,
}: LocaleSelectContentProps) => {
  const { locale } = useRouter();
  const { t } = useTranslation();

  const { countryCode } = getCountryConfigFromLocale(locale!);
  const { icon: LanguageIcon, label, languages } = countriesData[countryCode];

  if (!isCountriesScreenOpen) {
    return (
      <div className={styles.initialScreenContainer}>
        <span>{t('common:locale.initial.country')}</span>
        <AButton
          aria-label={t('common:locale.initial.cta.ariaLabel')}
          className={styles.countrySelectToggle}
          onClick={onClick}
        >
          <div className={styles.countrySelectToggleIcon}>{LanguageIcon}</div>
          <div className={styles.countrySelectToggleLabel}>{label}</div>
          <FaChevronDown className={styles.countrySelectToggleArrow} />
        </AButton>
        <span>{t('common:locale.initial.language')}</span>
        <RadioGroup
          ariaLabel={t('common:locale.ariaLabel')}
          className={styles.radioGroup}
          value={selectedLocale}
          onChange={onLocaleChange}
        >
          {languages.map(lang => (
            <RadioInput
              key={lang}
              labelComponent={RadioLabel}
              value={`${lang}-${countryCode}`}
              aria-label={t('common:locale.ariaLabel', {
                locale: `${languagesData[lang].label}-${label}`,
              })}
            >
              <span>{languagesData[lang].label}</span>
            </RadioInput>
          ))}
        </RadioGroup>
      </div>
    );
  }

  return (
    <div className={styles.countriesContainer}>
      {isBackButtonVisible && (
        <LocaleSelectBackButton
          className={styles.countriesBackBtn}
          onClick={onClick!}
        />
      )}
      <AccordionSingle className={styles.accordion} name="locale" collapsible>
        {Object.entries(countriesData)
          // move selected country to the top of the list
          .sort(([key]) => (key === countryCode ? -1 : 1))
          .map(([key, country]) => {
            const firstLanguage = country.languages[0];
            return country.languages.length > 1 ? (
              <AccordionItem
                className={styles.accordionItem}
                ariaLabel={t('common:locale.countriesList.ariaLabel', {
                  country: country.label,
                })}
                name="country"
                key={key}
                value={key}
                title={
                  <div className={styles.countrySelectToggle}>
                    <div className={styles.countrySelectToggleIcon}>
                      {country.icon}
                    </div>
                    <span
                      className={cls(styles.countrySelectToggleLabel, {
                        [styles.selected]: countryCode === key,
                      })}
                    >
                      {country.label}
                    </span>
                    <HiOutlinePlus className={styles.accordionIcon} />
                  </div>
                }
              >
                <RadioGroup
                  ariaLabel={t('common:locale.ariaLabel')}
                  className={cls(
                    styles.radioGroup,
                    styles.countriesContainerRadioGroup
                  )}
                  value={selectedLocale}
                  onChange={onLocaleChange}
                >
                  {country.languages.map(lang => {
                    const value = `${lang}-${key}`;
                    return (
                      <RadioInput
                        key={lang}
                        labelComponent={RadioLabel}
                        value={value}
                        aria-label={t('common:locale.ariaLabel', {
                          locale: `${languagesData[lang].label}-${country.label}`,
                        })}
                      >
                        <span>{languagesData[lang].label}</span>
                      </RadioInput>
                    );
                  })}
                </RadioGroup>
              </AccordionItem>
            ) : (
              <div className={styles.oneLangBtnContainer} key={key}>
                <H3>
                  <AButton
                    aria-label={t('common:locale.ariaLabel', {
                      locale: country.label,
                    })}
                    onClick={() => onLocaleChange(`${firstLanguage}-${key}`)}
                  >
                    <div className={styles.countrySelectToggle}>
                      <div className={styles.countrySelectToggleIcon}>
                        {country.icon}
                      </div>
                      <span
                        className={cls(styles.countrySelectToggleLabel, {
                          [styles.selected]: countryCode === key,
                        })}
                      >
                        {country.label}
                      </span>
                    </div>
                  </AButton>
                </H3>
              </div>
            );
          })}
      </AccordionSingle>
    </div>
  );
};

const RadioLabel = ({
  isSelected,
  isFocusVisible,
  className,
  children,
}: RadioInputLabelProps) => {
  const RadioIcon = !isSelected ? MdRadioButtonUnchecked : MdRadioButtonChecked;
  return (
    <Label
      version="body1"
      className={cls(
        styles.radioLabel,
        { [styles.selected]: isSelected },
        { [styles.focus]: isFocusVisible },
        className
      )}
    >
      <RadioIcon size={24} className={styles.radioIcon} />
      {children}
    </Label>
  );
};
