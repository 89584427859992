import { FaChevronDown } from '@react-icons/all-files/fa/FaChevronDown';
import { FaTimes } from '@react-icons/all-files/fa/FaTimes';
import cls from 'clsx';
import debounce from 'lodash/debounce';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useRef, useState } from 'react';
import { useClickAway, useMedia } from 'react-use';
import { Button } from '@/components/atoms/Button/Button';
import { Drawer } from '@/components/atoms/Drawer/Drawer';
import { P } from '@/components/atoms/Typography/Typography';
import { countriesData } from '@/data/countries';
import { getCountryConfigFromLocale } from '@/lib/i18n/getCountryConfigFromLocale';
import { getLangFromLocale } from '@/lib/i18n/getLangFromLocale';
import breakpoints from '@/theme/breakpoints.module.scss';
import { navigateToNewLocale } from '@/utils/navigate/navigateToNewLocale';
import styles from './LocaleSelect.module.scss';
import { LocaleSelectBackButton } from './LocaleSelectBackButton/LocaleSelectBackButton';
import {
  LocaleSelectContent,
  LocaleSelectContentProps,
} from './LocaleSelectContent/LocaleSelectContent';
import { LocaleSelectPopup } from './LocaleSelectPopup/LocaleSelectPopup';
import { LocaleSelectTrigger } from './LocaleSelectTrigger/LocaleSelectTrigger';

interface Props {
  className?: string;
}

export const LocaleSelect = ({ className }: Props) => {
  const router = useRouter();
  const { t } = useTranslation();
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const popupRef = useRef<HTMLDivElement>(null);
  const toggleRef = useRef<HTMLButtonElement>(null);
  const isSmallScreen = useMedia(`(max-width: ${breakpoints.mediaSM})`, false);
  const [selectedLocale, setSelectedLocale] = useState(router.locale);
  const [isCountriesScreenOpen, setIsCountriesScreenOpen] = useState(false);

  const { countryCode } = getCountryConfigFromLocale(router.locale!);
  const currentLang = getLangFromLocale(router.locale!);
  const { icon: LanguageIcon } = countriesData[countryCode];
  const isLoading = selectedLocale !== router.locale;

  useClickAway(popupRef, e => {
    if (toggleRef.current && !toggleRef.current.contains(e.target as Node)) {
      setIsSelectOpen(false);
    }
  });

  const onTriggerClick = () => !isLoading && setIsSelectOpen(!isSelectOpen);

  const onLocaleChange = (locale: string) => {
    if (isLoading) return;

    const { countryCode: country } = getCountryConfigFromLocale(locale);
    const language = getLangFromLocale(locale);

    setSelectedLocale(locale);

    closeSelectWithDebounce();

    navigateToNewLocale({
      language,
      country,
      currentCountry: countryCode,
      router,
    });
  };

  const closeSelectWithDebounce = debounce(() => {
    setIsSelectOpen(false);
  }, 100);

  const changeLocaleSelectContentScreen = () =>
    setIsCountriesScreenOpen(!isCountriesScreenOpen);

  const localeSelectContentProps: LocaleSelectContentProps = {
    isCountriesScreenOpen,
    selectedLocale,
    onClick: changeLocaleSelectContentScreen,
    onLocaleChange,
  };

  return (
    <div className={cls(styles.container, className)}>
      <LocaleSelectTrigger
        ref={toggleRef}
        aria-label={t('common:locale.cta.ariaLabel')}
        chevronIcon={
          !isSmallScreen && (
            <FaChevronDown
              className={cls(styles.chevronDown, {
                [styles.expanded]: isSelectOpen,
              })}
            />
          )
        }
        flagIcon={LanguageIcon}
        languageCode={currentLang.toUpperCase()}
        onClick={onTriggerClick}
      />
      {isSmallScreen ? (
        <Drawer
          ariaLabel={t('common:modal.info.label')}
          className={styles.drawer}
          isOpen={isSelectOpen}
          onDismiss={() => setIsSelectOpen(false)}
          onExitComplete={() =>
            isCountriesScreenOpen && changeLocaleSelectContentScreen()
          }
        >
          <div className={styles.buttonGroup}>
            {isCountriesScreenOpen && (
              <LocaleSelectBackButton
                onClick={changeLocaleSelectContentScreen}
              />
            )}
            <Button
              className={styles.drawerCrossBtn}
              onClick={() => setIsSelectOpen(false)}
              variant="icon"
              color="default"
              aria-label={t('drawer.close')}
            >
              <FaTimes size="24px" />
            </Button>
          </div>
          {isCountriesScreenOpen && (
            <div className={styles.countriesListTitle}>
              <P version="level4">{t('common:locale.country')}</P>
            </div>
          )}
          <LocaleSelectContent
            isBackButtonVisible={false}
            typographyVersion={isSmallScreen && 'level4'}
            {...localeSelectContentProps}
          />
        </Drawer>
      ) : (
        <LocaleSelectPopup
          ref={popupRef}
          isOpen={isSelectOpen}
          onAnimationComplete={() =>
            isCountriesScreenOpen && changeLocaleSelectContentScreen()
          }
        >
          <LocaleSelectContent {...localeSelectContentProps} />
        </LocaleSelectPopup>
      )}
    </div>
  );
};
